import Modal from 'bootstrap/js/src/modal';
import {parseHTML} from "../Core/common";
import IMask from 'imask';

export const modal = (message, showEvent = function (){}, hiddenEvent = function (){}) => {
    document.querySelectorAll('.modal').forEach(t => {
        let modal = Modal.getInstance(t);
        if(modal){
            modal.hide();
        }
        t.remove();
    });

let element =  parseHTML(`<div class="modal fade modal-appointment" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body p-20 bg-lblue">
                <div class="p-sm-30 position-relative">
                    <button class="btn-close position-absolute end-0 top-0" data-bs-dismiss="modal"></button>
                    ${message}
                </div>
            </div>
        </div>
    </div>
</div>`);

    document.querySelector('body').append(element);


    let to = document.querySelector('.modal');

    let modal = new Modal(to);
    modal.show();

    if(to.querySelector('.input-phone')){
        IMask(
            to.querySelector('.input-phone'), {
                mask: '8 (000) 000-00-00'
            });
    }

    let show = (event) => {
        showEvent(to, modal, event);
    };

    let hide = event =>{
        to.remove();
        to.removeEventListener('shown.bs.modal', show);
        to.removeEventListener('hidden.bs.modal', show);
        hiddenEvent(event);
    };

    to.addEventListener('shown.bs.modal', show);
    to.addEventListener('hidden.bs.modal', hide);
};


import {fieldValidator, formDataFBuilder} from "../Core/common";
import Agent from "../Core/agent";
import {notice} from "../UI/notice";
import {modal} from "../UI/modal";

const state = {
    isPending: false
};

const sendInfo = async (output, button) => {
    button.classList.add('preloader');
    let response = await Agent.post('/api/form/', output);
    try {
        return await response.json();
    } catch (e) {
        notice(e.message);
    } finally {
        button.classList.remove('preloader');
    }
};

const initForm = async form => {
    let required_field_flag;

    form.querySelectorAll(`input, textarea`).forEach(el => {
        if (el.hasAttribute('name')) {
            el.addEventListener('input', event => {
                    let input = fieldValidator.bind(null, event.currentTarget);
                    clearTimeout(required_field_flag);
                    required_field_flag = setTimeout(input, 1000);
                }
            );

            el.addEventListener('focus', () => {
                el.classList.remove('is-invalid');
                el.classList.remove('is-valid');
            });

            el.addEventListener('blur', event => {
                let input = fieldValidator.bind(null, event.currentTarget);
                clearTimeout(required_field_flag);
                required_field_flag = setTimeout(input, 10);
            });
        }
    });

    let buttons = form.querySelectorAll('button, input[type="submit"]');
    if (buttons) {
        let last = buttons[buttons.length - 1];
        let inputs = form.querySelectorAll(`input, textarea`);

        last.addEventListener('click', event => {
            event.preventDefault();
            event.stopPropagation();
            let isError = false;

            inputs.forEach(el => {
                if (el.hasAttribute('name')) {
                    if (!fieldValidator(el)) {
                        isError = true;
                    }
                }
            });

            if (!isError) {
                let data = formDataFBuilder(form);
                data.append('key', 'msg_form');
                data.append('page', window.location.pathname.substr(1));
                data.append('city', localStorage.getItem('city'));
                data.append('time_zone', document.querySelector('body').dataset.time_zone || 0);
                data.append('form_name', form.getAttribute('name'));
                data.append('ya_event_id', form.dataset.ya_id);
                data.append('ya_event_key', form.dataset.ya_key);
                let result = sendInfo(data, last);
                result.then(e => {
                    if (e.success) {
                        let filter = ['hidden', 'radio', 'checkbox', 'number', 'select', 'count', 'product', 'product_dependent'];

                        inputs.forEach(el => {
                            if (!filter.includes(el.type)) {
                                el.value = '';
                            }

                            if (el.type === 'checkbox') {
                                el.checked = false;
                            }

                            el.classList.remove('is-invalid');
                            el.classList.remove('is-valid');
                        });

                        modal(e.message, function (el, modal) {
                            setTimeout(modal.hide, 2000);
                        });

                    } else {
                        notice(e.message);
                    }
                });
            }
        });
    }
};

const openForm = async button => {
    button.disabled = true;
    if (!state.isPending) {
        state.isPending = true;

        let cnt = 0;
        let block = button.closest('.rbm_js_product_item, .rbm_js_product_item_in_goods');
        if (block) {
            let countElement = block.querySelector('.count_product_item');
            if (countElement) {
                cnt = countElement.value;
            }
        }

        let output = new FormData();
        output.append('key', 'open_form');
        output.append('ya_event_id', button.dataset.yaID);
        output.append('ya_event_key', button.dataset.yaKey);
        output.append('form_name', button.name || button.dataset.name);
        output.append('product', button.dataset.product_id || 0);
        output.append('user', button.dataset.user);
        output.append('count', cnt);
        output.append('data_json', JSON.stringify({}));

        button.classList.add('preloader');

        let response = await Agent.post('/includes/modules/form/show_form.php', output);
        try {
            const result = await response.json();

            modal(result.form || '', function (el) {
                let form = el.querySelector('form');
                if (form) {
                    initForm(form);
                }
            });
        } catch (e) {
            notice(e.message);
        } finally {
            button.classList.remove('preloader');
            button.disabled = false;
            state.isPending = false;
        }

    }
};

export default class Forms {
    constructor(events) {
        this.events = events;
        this.initInlineForm(document);
        this.initModalForm(document);
    }

    initInlineForm(element) {
        ['.consultation_specialist', '.callback_footer', '.make_appointment', '.sign_up_procedure']
            .forEach(selector => {
                element.querySelectorAll(selector).forEach(item => {
                    initForm(item);
                });
            });
    }

    initModalForm(element) {
        ['.callback_header', '.open_callback_footer', '.action'].forEach(selector => {
            element.querySelectorAll(selector).forEach(item => {
                item.addEventListener('click', () => {
                    openForm(item);
                });
            });
        });
    }
}
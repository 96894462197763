import Toast from 'bootstrap/js/src/toast';
import {parseHTML} from "../Core/common";

export const notice = (message, delay = 4000) => {
    let container = document.querySelector('.js_toast_container');
    if (!container) {
        container = parseHTML(`<div aria-live="polite" aria-atomic="true">
                                        <div class="toast-container position-fixed top-0 end-0 p-3 js_toast_container" style="z-index: 10000;"> 
                                        </div>
                                    </div>`);

        document.querySelector('body').append(container);
    }

    let id = Math.random().toString().substring(2);

    let element = parseHTML(`<div class="toast" id="toast_${id}" role="alert" aria-live="assertive" data-bs-animation="true" data-bs-delay="${delay}" aria-atomic="true">
                                        <div class="toast-header">
                                            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                                        </div>
                                        <div class="toast-body">
                                            ${message}
                                        </div>
                                    </div>`);

    container.append(element);

    let to = document.querySelector(`#toast_${id}`);
    let t = new Toast(to);
    t.show();

    to.addEventListener('hidden.bs.toast', () => to.remove());
};
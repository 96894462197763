import {modal} from "../UI/modal";
import {notice} from "../UI/notice";
import {fieldValidator, formDataFBuilder} from "../Core/common";
import Agent from "../Core/agent";

const state = {
    addButton: null,
    form: null,
    modal: null
};

const addComment = form => {
    let inputs = form.querySelectorAll(`input, textarea, select`);
    let isError = false;

    inputs.forEach(el => {
        if (el.hasAttribute('name')) {
            if (!fieldValidator(el)) {
                isError = true;
            }
        }
    });

    if (!isError) {
        let data = formDataFBuilder(form);
        state.addButton.disabled = true;
        state.addButton.classList.add('preloader');

        Agent.post('/api/comment/create', data)
            .then(e => e.json())
            .then(e => {
                notice(e.message);
                if (e.success && state.modal) {
                    state.modal.hide();
                }
            })
            .catch(e => console.log(e))
            .finally(() => {
                state.addButton.classList.remove('preloader');
                state.addButton.disabled = false;
            });
    }
};

const initAddCommentEvent = form => {
    state.addButton = form.querySelector('.js_add_comment');
    state.form = form;

    let required_field_flag;

    form.querySelectorAll(`input, textarea, select`).forEach(el => {
        if (el.hasAttribute('name')) {
            el.addEventListener('input', event => {
                    let input = fieldValidator.bind(null, event.currentTarget);
                    clearTimeout(required_field_flag);
                    required_field_flag = setTimeout(input, 1000);
                }
            );

            el.addEventListener('focus', () => {
                el.classList.remove('is-invalid');
                el.classList.remove('is-valid');
            });

            el.addEventListener('blur', event => {
                let input = fieldValidator.bind(null, event.currentTarget);
                clearTimeout(required_field_flag);
                required_field_flag = setTimeout(input, 10);
            });
        }
    });

    state.addButton.addEventListener('click', event => {
        event.preventDefault();
        addComment(form);
    });
};

const removeAddCommentEvent = () => {
    if (state.addButton) {
        state.addButton.removeEventListener('click', addComment.bind(null, state.addButton));
        state.addButton = null;
        state.form = null;
        state.modal = null;
    }
};

const showModel = (id, button) => {
    button.disabled = true;
    button.classList.add('preloader');

    Agent.post(`/api/comment/form/${id}`)
        .then(e => e.json())
        .then(e => {
            if (e.success) {
                modal(e.data,
                    function (modal, obj) {
                        state.modal = obj;
                        initAddCommentEvent(modal.querySelector('form'));
                    },
                    removeAddCommentEvent);
            } else {
                notice(e.message);
            }
        }).catch(e => console.log(e))
        .finally(() => {
            button.classList.remove('preloader');
            button.disabled = false;
        });


};

export default class Comment {
    constructor(events) {
        this.events = events;
        document.querySelectorAll('.js_open_modal_add_comment').forEach(item => {
            let id = item.dataset.id;
            item.addEventListener('click', () => {
                showModel(id, item);
            });
        });
    }
}
import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';
import yall from 'yall-js';
import {parseHTML} from "./Components/Core/common";
import JsTabs from "js-tabs";
import lgZoom from 'lightgallery/plugins/zoom';

import Events from "./Components/Core/Events";
import Forms from "./Components/Forms/forms";
import Comment from "./Components/Comments/comment";
import Search from "./Components/search";

import IMask from 'imask';
import MmenuLight from 'mmenu-light';


const state = {
    menuLoadPending: false
};

let events = new Events();
new Forms(events);
new Comment(events);
new Search(events);

document.querySelectorAll('.procedure-gallery').forEach(item => {
    lightGallery(item, {
        plugins: [lgZoom],
        speed: 500,
        selector: '.ratio'
    });
});

document.querySelectorAll('.price-tabs').forEach(price => {
    const priceTabs = new JsTabs({
        elm: price,
        shouldScrollTabIntoView: false,
    });
    priceTabs.init();
    price.querySelector(".js-tabs__tab").classList.add("active");
    price.querySelector(".js-tabs__content").classList.add("active");
});

document.querySelectorAll('.video-youtube').forEach(item => {
    lightGallery(item, {
        plugins: [lgVideo],
        selector: '.ratio'
    });
});

let menuAnchor = [];
document.querySelectorAll('.js_content h2, .js_content h3').forEach((item, i) => {
    item.id = `anchor-${i}`;
    let anchor = parseHTML(`<a class="lh-1_2 text-decoration-none js_content_menu_item" href="#anchor-${i}" title="${item.innerHTML}">${item.innerHTML}</a>`);
    menuAnchor.push(anchor);
});

if (menuAnchor.length) {
    let contentMenu = parseHTML(`<div class="content-aside-item p-xl-40 p-20 bg-lblue ">
                            <div class="fs-20 lh-1_2 text-uppercase mb-20 content-aside-item-heading position-relative">
                                Оглавление
                            </div>
                            <div class="content-aside-item-block d-flex flex-column js_content_menu_items"></div>
                        </div>`);
    let menuItems = contentMenu.querySelector('.js_content_menu_items');

    document.querySelectorAll('.js_content_menu').forEach(item => {
        menuAnchor.forEach(el => menuItems.append(el));
        item.prepend(contentMenu);
    });

    document.querySelectorAll('.js_content_menu_item').forEach(item => {
        item.addEventListener('click', event => {
            event.preventDefault();
            const id = item.getAttribute('href');
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        });
    });
}


document.querySelectorAll('.menu-item').forEach(block => {
    let menuBtn = block.querySelector('.menu-btn');
    let menuBlock = block.querySelector('.menu-block');
    let menu = block.querySelectorAll('.menu');
    menuBtn.addEventListener('mouseover', function () {
        menuBlock.classList.add('active');
        menuBtn.classList.add('active');
    });
    menu.forEach(function (item) {
        item.addEventListener('mouseleave', function () {
            let timer = setTimeout(function () {
                menuBlock.classList.remove('active');
                menuBtn.classList.remove('active');
            }, 200);
            menuBlock.addEventListener('mouseover', function () {
                menuBlock.classList.add('active');
                menuBtn.classList.add('active');
                clearTimeout(timer);
            });
        });
    });
});

if (document.querySelector('.article-block')) {
    const myTabs = new JsTabs({
        elm: '.article-block',
        shouldScrollTabIntoView: false,
    });
    myTabs.init();
}

if (document.querySelector('.spoiler')) {
    document.querySelectorAll('.spoiler-btn').forEach((item, index) => {
        item.addEventListener('click', function (event) {
            event.preventDefault();
            item.classList.toggle('active');
            const container = document.querySelectorAll('.spoiler-block')[index];
            if (!container.classList.contains('active')) {
                container.classList.add('active');
                container.style.height = 'auto';
                let height = container.clientHeight + 'px';
                container.style.height = '0px';
                setTimeout(function () {
                    container.style.height = height;
                }, 0);
            } else {
                container.style.height = '0px';
                container.addEventListener('transitionend', function () {
                    container.classList.remove('active');
                }, {
                    once: true
                });

            }
        });
    });
}

yall({
    observeChanges: true,
    events: {
        load: function (event) {
            if (!event.target.classList.contains("lazy") && event.target.nodeName === "IMG") {
                event.target.classList.add("yall-loaded");
            }
        },
        play: function (event) {
            if (event.target.nodeName === "VIDEO") {
                event.target.nextElementSibling.classList.add("visible");
            }
        }
    }
});

document.querySelectorAll('.input-phone').forEach(item => {
    IMask(item, {mask: '8 (000) 000-00-00'});
});

const mobileMenu = new MmenuLight(
    document.querySelector("#mobile-menu",)
);

mobileMenu.navigation({
    title: "Меню",
    theme: "dark",
});
const drawerMenu = mobileMenu.offcanvas();

document.querySelector('a[href="#mobile-menu"]').addEventListener('click', (event) => {
    event.preventDefault();
    drawerMenu.open();
});

const js_article_block = document.querySelector('.js_article_block');

if (js_article_block) {
    const js_anchors_block = document.querySelector('.js_anchors');
    if (js_anchors_block) {
        const js_anchors_div = js_anchors_block.querySelector('.js_anchor_div');
        if (js_anchors_div) {
            let anchors = js_article_block.querySelectorAll('h2, h3');
            if(anchors.length){
                js_article_block.classList.remove('col-auto');
                js_article_block.classList.add('col-xxl-6');
                js_article_block.classList.add('col-xl');
            }
            anchors.forEach((item, i) => {

                item.id = `anchor-${i}`;
                let anchor = parseHTML(`<a class="lh-1_2 text-decoration-none" href="#anchor-${i}" title="Перейти к ${item.innerText}">
                                                    ${item.innerText}
                                            </a>`);
                js_anchors_div.append(anchor);
                js_anchors_block.classList.remove('d-none');
            });
        }
    }
}
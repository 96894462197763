import Agent from "./Core/agent";

export default class Search {
    constructor(events) {
        this.events = events;
        let timers;
        const $this = this;

        document.querySelectorAll('.search-field').forEach(item => item.addEventListener('input', event => {
            clearTimeout(timers);
            timers = setTimeout($this.searchParts.bind($this, item.value, event.currentTarget), 500);
        }, false));

        document.addEventListener('click', event => {
            if(!event.target.closest(".js_search_block")){
                clearTimeout(timers);
                document.querySelectorAll('.search-block-auto').forEach(item => {
                    item.classList.remove('active');
                });
            }
        }, false);

        document.querySelectorAll('.search-button').forEach(item => item.addEventListener('click', () => {
            let form  = item.closest('form');
            if(form){
                let field = form.querySelector('.search-field');
                if(field && field.value.length > 2){
                    window.location = "/search.html?stext=" + field.value;
                }
            }
        }));
    }

    searchParts(searchPhrase, field) {
        let searchResult = field.closest('form').querySelector('.search-block-auto');
        if (searchResult && searchPhrase.trim().length > 1) {
            const searchBlock = searchResult.querySelector('.js_products');

            if(searchBlock){
                searchBlock.innerHtml = '';
                searchBlock.classList.add('preloader');
                let output = {
                    'path': 'get_autocomplete',
                    'search_str': searchPhrase,
                };
                Agent.post('/ajax.php', 'data=' + JSON.stringify(output), {headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }}).then(e => e.json()).then(e => {
                    if(e.success && e.data.length){
                        searchBlock.innerHTML = e.data;
                        searchResult.classList.add('active');
                    }else{
                        searchBlock.innerHtml = '';
                        searchResult.classList.remove('active');
                    }
                }).catch(() => {
                    searchBlock.innerHtml = '';
                    searchResult.classList.remove('active');
                }).finally(() => {
                    searchBlock.classList.remove('preloader');
                });
            }
        }
    }
}

import {merge} from "./common";

const defaultOptions = {
    cache: 'no-cache'
};

export default class Agent {
    static async get(url) {
        try {
            return await Agent.executeQuery(url);
        } catch (e) {
            throw new Error(e);
        }
    }

    static async post(url, body, options = {}) {
        try {
            return await Agent.executeQuery(url, merge(defaultOptions, {method: 'POST', body: body}, options));
        } catch (e) {
            throw new Error(e);
        }
    }

    static async put(url, body, options = {}) {
        try {
            return Agent.post(url, body, merge(defaultOptions, options, {method: 'PUT'}));
        } catch (e) {
            throw new Error(e);
        }
    }

    static async executeQuery(url, options = null) {
        try {
            return await fetch(url, options);
        } catch (e) {
            throw new Error(e);
        }
    }
}
export default class Events {

    constructor() {
        this.eventListeners = [];
    }

    dispatch(event) {
        if (this.eventListeners[event]) {
            for (let listener in this.eventListeners[event]) {
                if (!this.eventListeners[event].hasOwnProperty(listener)) continue;

                let obj = this.eventListeners[event][listener];

                let arr = [].slice.call(arguments, 1);
                obj.listener.apply(obj.context, arr);
            }
        }
    }

    addListener(name, context, listener) {
        if (!this.eventListeners[name]) {
            this.eventListeners[name] = [];
        }
        if (this.eventListeners[name].find(current => current.context === context && current.listener === listener) === undefined) {
            this.eventListeners[name].push({context: context, listener: listener});
        }
    }
}
import {Validator} from "./validator";

export function numberFormat(number, decimals = 0, dec_point = ' ', thousands_sep = ' ') {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s,
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export function empty(mixedVar) {
    return (!mixedVar || mixedVar === "" || mixedVar === 0 || mixedVar === "0" || mixedVar === null || mixedVar === false);
}

export function OOPSSpell($num, $base, $endings) {
    if ($num % 100 >= 11 && $num % 100 <= 19) {
        return $base + $endings[2];
    }

    switch ($num % 10) {
        case 1:
            return $base + $endings[0];
        case 2:
        case 3:
        case 4:
            return $base + $endings[1];
        default:
            return $base + $endings[2];
    }
}

export function param(a) {
    let s = [];
    let add = function (k, v) {
        v = typeof v === 'function' ? v() : v;
        v = v === null ? '' : v === undefined ? '' : v;
        s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
    };
    let buildParams = function (prefix, obj) {
        let i, len, key;

        if (prefix) {
            if (Array.isArray(obj)) {
                for (i = 0, len = obj.length; i < len; i++) {
                    buildParams(
                        prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
                        obj[i]
                    );
                }
            } else if (String(obj) === '[object Object]') {
                for (key in obj) {
                    buildParams(prefix + '[' + key + ']', obj[key]);
                }
            } else {
                add(prefix, obj);
            }
        } else if (Array.isArray(obj)) {
            for (i = 0, len = obj.length; i < len; i++) {
                add(obj[i].name, obj[i].value);
            }
        } else {
            for (key in obj) {
                buildParams(key, obj[key]);
            }
        }
        return s;
    };

    return buildParams('', a).join('&');
}

export const fieldValidator = inputs => {
    let error;
    if (inputs.getAttribute('type') === 'checkbox' && inputs.hasAttribute('required')) {
        error = !inputs.checked;
    } else if (inputs.getAttribute('type') === 'text' && inputs.hasAttribute('required')) {
        error = !Validator.length(inputs.value, 1);
    } else if (inputs.getAttribute('type') === 'email' && inputs.hasAttribute('required')) {
        error = !Validator.isEmail(inputs.value);
    } else if (inputs.getAttribute('type') === 'tel' && inputs.hasAttribute('required')) {
        error = !Validator.isPhone(inputs.value);
    } else {
        error = !Validator.notBlank(inputs);
    }

    if (error) {
        inputs.classList.add('is-invalid');
        inputs.classList.remove('is-valid');
    } else {
        inputs.classList.add('is-valid');
        inputs.classList.remove('is-invalid');
    }
    return !error;
};

export const parseBool = function (str) {
    if (typeof str === 'string' && str.toLowerCase() === 'true') {
        return true;
    }
    if (typeof str === 'boolean') {
        return str;
    }

    return (parseInt(str) > 0);
};

export const formDataFBuilder = form => {
    const formData = new FormData();
    form.querySelectorAll('input, select, textarea').forEach(item => {
        if (!item.getAttribute("disabled") && !item.getAttribute("readonly") && item.getAttribute("name")) {
            switch (item.tagName) {
                case"INPUT":
                    if ("checkbox" === item.getAttribute("type")) {
                        formData.append(item.getAttribute("name"), item.checked);
                    } else if ("file" === item.getAttribute("type")) {
                        formData.append(item.getAttribute("name"), item.files[0]);
                    } else {
                        formData.append(item.getAttribute("name"), item.value.trim());
                    }
                    break;
                default:
                    formData.append(item.getAttribute("name"), item.value);
            }
        }
    });
    return formData;
};

export function merge(...objects) {
    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach(key => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal);
            }
            else if (Validator.isObject(pVal) && Validator.isObject(oVal)) {
                prev[key] = merge(pVal, oVal);
            }
            else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
}

export function parseHTML(html) {
    const t = document.createElement('template');
    t.innerHTML = html;
    return t.content.cloneNode(true);
}
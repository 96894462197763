import {empty} from './common';

export class Validator {
    static notBlank(field) {
        if (field.hasAttribute('required')) {
            return !empty(field.value);
        }
        return true;
    }

    static isPhone(value) {
        let str = (value).replace(/[^0-9]/g, '');
        return str.length > 10;
    }

    static isEmail(value) {
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return reg.test(value);
    }

    static length(value, min, max) {
        if(Validator.isNumber(value)){
            if (min !== undefined && max !== undefined) {
                return value >= min && value <= max;
            }
            if (min !== undefined) {
                return value >= min;
            }

            if (max !== undefined) {
                return value <= max;
            }
        }else{
            let val = String(value);
            if (min !== undefined && max !== undefined) {
                return val.length > min && val.length < max;
            }

            if (min !== undefined) {
                return val.length >= min;
            }

            if (max !== undefined) {
                return val.length <= max;
            }
        }

        return undefined;
    }

    static isNumber(val) {
        return /^[-]?\d+$/.test(val);
    }

    static isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }
}
